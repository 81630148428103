import { Component, Input, OnInit } from "@angular/core";
import { BlogSummaryResponse, CategoryResponse } from 'src/app/models/homepage.response';


@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.css"]
})
export class CategoriesComponent implements OnInit {
  @Input() categories:CategoryResponse

  constructor() {}


  ngOnInit() {

  }

}
