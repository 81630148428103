import { Directive, OnInit, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appHoverColor]'
})
export class HoverColorDirective implements OnInit {
  @HostBinding('style.color') backgroundColor:string ='#007bff'; 

  constructor() { }

  ngOnInit(){

  }

  @HostListener('mouseenter') mouseover (eventData: Event){
    this.backgroundColor = '#ff8100'; 
  }

  @HostListener('mouseleave') mouseleave (eventData:Event){
    this.backgroundColor= '#007bff'; 
  }

}
