 <!-- Navigation -->
 <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <div class="container">
      <a class="navbar-brand" href="/">Picoage Blog</a>
     
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleCollapsed()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive" [ngClass]= "{'collapse': isCollapse, 'navbar-collapse':true}">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <a class="nav-link" href="https://github.com/picoage"  target="_blank"> <i class="fa fa-github fa-2x" aria-hidden="true"> </i>
              <span class="sr-only">(current)</span>
            </a>
          </li>
          <li class="nav-item">
            
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.linkedin.com/in/picoage/" target="_blank"><i class="fa fa-linkedin fa-2x" aria-hidden="true"> </i></a>
          </li>
          <li class="nav-item">
            
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://twitter.com/akavishnu" target="_blank"><i class="fa fa-twitter-square fa-2x" aria-hidden="true"> </i></a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">Contact</a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
