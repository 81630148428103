<section>
 
    <div *ngFor="let blogSummary of summaries" class="wrap">
      <a [routerLink]="['/blog',blogSummary.blogId]"></a>
      
      <div  class="text-wrap vcenter">
        <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">{{blogSummary.title}}</h2>

        <p class="mbr-fonts-style text1 mbr-text display-6">{{blogSummary.summary}}</p>
      </div>
    </div>
</section>