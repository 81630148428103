import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { AppConfigService } from "../config/app-config.service";
import { AuthenticationResponse } from "../modules/authenticationresponse.model";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  //test
  baseUrl: string = AppConfigService.setting.baseUrl;
  tokenExpiration: Date;
  token: string;
  auth: AuthenticationResponse = new AuthenticationResponse();
  constructor(private http: HttpClient) {}

  getAuth = () => {
    console.log(this.tokenExpiration);

    if (!this.tokenExpiration || new Date() > this.tokenExpiration) {
      return this.http
        .post<AuthenticationResponse>(
          `${this.baseUrl}api/v1/authentication/api-token/`,
          {
            username: "dev@test.com",
            password: "6a>2=b9piSo6n-d",
          }
        )
        .pipe(
          tap((rsp) => {
            const expireDate = new Date(0);
            expireDate.setUTCSeconds(rsp.expireDate);
            this.tokenExpiration = expireDate;
            this.auth.token = rsp.token;
            this.auth.userName = rsp.userName;
          })
        );
    } else {
      const observer = new Observable<AuthenticationResponse>((obs) => {
        obs.next(this.auth);
      });
      return observer;
    }
  };
}
