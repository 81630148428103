import { Component, Input, OnInit } from '@angular/core';
import { BlogSummaryResponse, HomePageResponse } from 'src/app/models/homepage.response';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {
@Input() summaries: BlogSummaryResponse; 

  card:{title:string ,content:string}
  cards:string[];

  constructor() { }

  ngOnInit() {
   let a = this.summaries; 
    console.log(a); 
    this.card = {title:'Ignite 2020',content:'Microsoft Ignite tour took place at London excel centre on 16/01/2020 and 17/02/2020. The event included a wide range of learning path from Azure fundamentals to Office 364, for the full range of list please visit'};
    //this.card.content='This is information about Micrososoft event ingnite in London';
    //this.cards.push(this.card.title, this.card.content)
  }

   

}