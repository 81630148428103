import { Component, OnInit } from "@angular/core";
import { BlogSummaryResponse, CategoryResponse, HomePageResponse } from "src/app/models/homepage.response";

import { HomeService } from "./home.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  constructor(private service: HomeService) {}

  homepageresponse: HomePageResponse;
  summaries: BlogSummaryResponse[] = []; 
  catagories: CategoryResponse[] = [];  
  showspinner:boolean = true; 

  ngOnInit(): void {
    this.getHomeContent();
  }

  getHomeContent() {
    console.log(this.showspinner); 
    this.service.fetchHomeDetailsFromApi().subscribe((data) => { 
        
    this.homepageresponse= data; 
    this.summaries = data.blogSummaries; 
    this.catagories = data.categoriesResponse; 
    if(data.blogSummaries.length > 0)
    {
      this.showspinner =false; 

    }   
       console.log(this.showspinner); 
     });
       
  console.log(this.showspinner); 
  }
}
