<app-navbar></app-navbar>



<div class="container">
       
<div *ngIf="showspinner">
    <div class="row">
        <div class="col-lg-8">
            <br><br><br><br>
    <mat-progress-bar mode="buffer"></mat-progress-bar>
        </div>
        </div>
</div>
    <div *ngIf="!showspinner" >
    <div class="row">
        <div class="col-lg-8">
            
            <div *ngIf="!myoutlet.isActivated">
                <div *ngIf="homepageresponse">  
                <app-cards [summaries]= "summaries"></app-cards>
            </div>
            </div>
            <!-- IF DOES NOT MATCH ROUTER  https://www.smashingmagazine.com/2018/11/a-complete-guide-to-routing-in-angular/ -->
            <router-outlet #myoutlet="outlet"></router-outlet>
        </div>
        <div class="col-md-4">
            <!-- <app-search></app-search> -->
            <app-categories [categories] ="catagories"></app-categories>

        </div>
    </div>
</div>

</div>