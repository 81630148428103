import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { exhaustMap, map } from "rxjs/operators";
import { HomePageResponse } from "src/app/models/homepage.response";
import { AuthenticationService } from "../../services/authentication.service";
import { AppConfigService } from "./../../config/app-config.service";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  baseUrl: string = AppConfigService.setting.baseUrl;

  constructor(
    private http: HttpClient,
    private authservice: AuthenticationService
  ) {}

  fetchHomeDetailsFromApi() {
    const url: string = `${this.baseUrl}api/v1/Blogs/homepage`;
    return this.authservice.getAuth().pipe(
      exhaustMap((data) => {
        const header = new HttpHeaders().set(
          "Authorization",
          `Bearer ${data.token}`
        );
        return this.http
          .get<HomePageResponse>(url, { headers: header })
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );  
  }
}
