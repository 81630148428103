export class HomePageResponse {
  
  blogSummaries: BlogSummaryResponse[];
  categoriesResponse: CategoryResponse[];
}

export class CategoryResponse {
  id: string;
  name: string;
  blogInfos: CategoryBlogInfo[]
}

export class BlogSummaryResponse
{
    blogId: string;
    title:string;
    summary: string;
}

export class CategoryBlogInfo
{
    blogId: string;
    blogTitle: string;
}