<!-- Post Content Column -->

<!-- Title -->
<h1 class="mt-4">{{content.title}}</h1>

<!-- Author -->
<p class="lead">
    by
    <a href="/">{{content.author}}</a>
</p>

<hr>

<!-- Date/Time -->
<p>Posted on {{content.postDate|date}} </p>

<hr>

<!-- Preview Image moved to content -->

<div [innerHTML]= content.content></div>

