import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MatExpansionModule } from "@angular/material/expansion";
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { ContentComponent } from "./components/content/content.component";
import { CommentsComponent } from "./components/comments/comments.component";
import { CommentsFormComponent } from "./components/comments-form/comments-form.component";
import { SearchComponent } from "./components/search/search.component";
import { CategoriesComponent } from "./components/categories/categories.component";
import { HoverColorDirective } from "./directives/hover-color.directive";
import { BlogComponent } from "./components/blog/blog.component";
import { CardsComponent } from "./components/cards/cards.component";
import { HomeComponent } from "./components/home/home.component";
import { AppConfigService } from "./config/app-config.service";

//TODO contentcomponent needs to be moved
const router: Routes = [
  { path: "blog/:id", component: ContentComponent },
  
];

//const router:Routes = [{path:'blog/:catagory',component: ContentComponent}]
export function initializeApp(appConfig: AppConfigService) {
  return () => appConfig.load();
}



@NgModule({
  declarations: [
    AppComponent,
    BlogComponent,
    NavbarComponent,
    ContentComponent,
    CommentsComponent,
    CommentsFormComponent,
    SearchComponent,
    CategoriesComponent,
    HoverColorDirective,
    CardsComponent,
    HomeComponent,
  ],

  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(router, { relativeLinkResolution: 'legacy', useHash:true }, ),
    BrowserAnimationsModule,
    MatExpansionModule,
    MatProgressBarModule,
  ],

  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true,
    },
  ],


  bootstrap: [AppComponent],
})
export class AppModule {}
