import { Category } from './catergory.model';

export class Blog {

    public title:string; 

    public date: Date; 

    public author: string; 

    public categories: Category[]; 
  
    public content:string; 

constructor()
{
    this.title= "Ignite 2020"; 
    
    this.date = new Date("2020-01-27"); 

    this.author = "Vishnu Kumar"

    this.content = `<p class='lead'>Microsoft Ignite tour took place at London excel centre on 16/01/2020 and 17/02/2020. The event included
    a
    wide range of learning path from Azure fundamentals to Office 364, for the full range of list please visit, <a
        href="https://london.myignitetour.techcommunity.microsoft.com/learning-paths">Ignite Path</a>. Being a
    developer, I decided to go for dev related events such as Azure, AI, Containers and Application monitoring and
    failure handling. </p>
    <h4>Day 1:</h4>
<p class="lead">Events I choose to go on day one was a combination of Azure fundamentals, AI and Containers. </p>

<h5>Session 1: </h5>
<p><strong>Title:</strong> Deploying and A/B testing without risks with deployment slots.</p>
<p> <strong> Speaker:</strong> Jeremy Likness </p>
<p>It was a quick 15 minutes session where the speaker demonstrates Blue-Green release with Canary testing. He showed us
    how
    to deploy a change close to Production environment using Azure deployment slots, swap the change to production and
    if any issues found in production then role it back just by the click of a button. </p>
<p>The most impressive element I learned from this session is provision Canary testing by diverting a small percentage
    of
    traffic to in Production close Environment. </p>
<h6>Useful Links</h6>
<a href="https://docs.microsoft.com/en-us/azure/app-service/deploy-staging-slots">Documentation</a>
<br /> <br />

<h5>Session 2: </h5>
<p> <strong>Title:</strong> Making sense of unstructured data with AI .</p>
<p> <strong> Speaker:</strong> Amy Boyd </p>
<p>It was deep diving session which included a case study to show how unstructured data from a form was converted into a
    searchable data using Microsoft cognitive service. </p>
<p><strong>Case Study:</strong> A company has accidentally deleted the sales data base and did not have a backup, but
    they had physical sales invoice in PDF format. Developer was asked to convert the data from PDF into a Json because
    I assume there where using Cosmos DB and make those data searchable. </p>
<p><strong>Solution:</strong> Ingest, Enrich and Explore pattern was used to solve this challenge.</p>
<img class="img-fluid rounded" src="" alt="Image">
<p>Ingest: Azure blob (file) storage was used to upload all PDF documents. </p>
<p>Enrich: Once files are uploaded, azure function blob trigger is used to transfer the uploaded files to a new preview
    service called form recogniser, which cracks the PDF into a structured Json format, which can be eventually saved in
    the database. </p>
<p>Explore: Azure search was used to explore the data from the database. </p>
<img class="img-fluid rounded" src="" alt="Image">
<h6>Useful Links:</h6>

<a
    href="https://techcommunity.microsoft.com/t5/microsoft-ignite-the-tour-2019/making-sense-of-your-unstructured-data-with-ai/m-p/907334">Presentation</a>

<br /> <br />
<h5>Session 3: </h5>
<p> <strong>Title:</strong> Azure networking basics.</p>
<p> <strong> Speaker:</strong> Ruth Yakubu </p>
<p>Some basic concepts of IAAS networking was demoed in this session. Speaker explained about VNet and VNet to VNet
    peering. Different types of VPN connection option were discussed, such as point-to-site, site-to-site and private
    site-to-site (Express route). Azure CDN and security aspects was briefly covered. </p>

<h6>Useful Links:</h6>

<a
    href="https://techcommunity.microsoft.com/t5/microsoft-ignite-the-tour-2019/azure-networking-basics/m-p/907399">Presentation</a>

<br /> <br />
<h5>Session 4: </h5>
<p> <strong>Title:</strong> Azure tooling and utilities.</p>
<p> <strong> Speaker:</strong> Matthew Soucoup </p>
<p>It was good to recap on tools and understand different types of utilities that can be used to find solutions for
    various types of challenged we developers face in day to day activities.<br /> VS code, Azure cloud shell
    (browser-based shell), Azure power shell and ARM template were demoed in this session. </p>
<p>Speaker also illustrated the when to use ARM and Azure CLI and its pros and cons. </p>

<h6>Useful Links:</h6>

<a
    href="https://techcommunity.microsoft.com/t5/microsoft-ignite-the-tour-2019/discovering-azure-tooling-and-utilities/m-p/907398">Presentation</a>
<a href="https://docs.microsoft.com/en-us/azure/templates/">, Documentation</a>

<br /> <br />
<h5>Session 5: </h5>
<p> <strong>Title:</strong> Taking apps to the next level with monitoring, performance and scaling</p>
<p> <strong> Speaker:</strong> Jimm Bennett </p>
<p>This session helped to understand different subsets of Azure monitoring and its various advantage in using them
    appropriately. </p>
<p><strong>Case study: </strong>Tailwind traders website was down over the weekend and no alerts where generated when
    the website was down.</p>
<p><strong>Solution:</strong> Azure monitoring and subsets of monitoring specially application insights and log
    analytics were used to find solution for the above case study. </p>
<p>Speaker demonstrated how to set up application insight for Application performance monitoring and to log custom
    application logs. Application failures where drilled deeply using failure analytical tool. Live telemetric, smart
    detection and dependency mapping where briefly touched.
</p>
<p> Log analytics was used to write custom queries in order to search for specific logs. </p>
<h6>Useful Links:</h6>

<a
    href="https://techcommunity.microsoft.com/t5/microsoft-ignite-the-tour-2019/taking-your-app-to-the-next-level-with-monitoring-performance/m-p/907383">Presentation</a>
<hr>

<h4>Day 2:</h4>
<p class="lead">On second day I decided to visit more stalls and lesser session in order explore various product which where build around Azure and products that support day to day development activities. </p>
<h5>Session 1:</h5>
<p><strong>Title: </strong>Exploring Containers and Orchestration in Azure </p>
<p><strong>Speaker: </strong>Patrick Chanezon </p>
<p>This session was more to do with why we need containers and how can we orchestrate containers in Azure. 
</p>
<p>
    Speaker explained the difference between containers and VMs and when to choose containers over VMs. 
 </p>
 <h6>Useful Links:</h6>
 <a
 href=" https://techcommunity.microsoft.com/t5/microsoft-ignite-the-tour-2019/exploring-containers-and-orchestration-in-azure/m-p/907395">Presentation</a>`; 
}

}