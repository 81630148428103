import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Subscription } from "rxjs";
import { Blog } from "src/app/modules/blog.module";
import { ContentModel } from "./content.model";
import { ContentService } from "./content.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.css"],
})
export class ContentComponent implements OnInit {
  blogs: { id: string };

  //Inject
  blog: Blog;
  content: ContentModel;
  value: string;

  paramsSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private contentService: ContentService,
    private sanitizer: DomSanitizer
  ) {
    this.blog = new Blog();
  }

  ngOnInit() {
    this.route.params.subscribe((pr: Params) => {
      let idFromPram: string = pr["id"];
      this.blogs = { id: pr["id"] };
      this.getContent(idFromPram);

      //pass id to api call and map to blog object
    });
  }

  getContent(id: string) {
    this.contentService.fetchContent(id).subscribe((data) => {
      this.content = data;
      this.content.content = this.sanitizer.bypassSecurityTrustHtml(
        this.content.content
      ) as string;
      console.log(this.content);
    });
  }
}
