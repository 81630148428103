import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/config/app-config.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ContentModel }from './content.model'

@Injectable({
  providedIn: 'root'
})
export class ContentService {

baseUrl:string = AppConfigService.setting.baseUrl; 


  constructor(private httpClient:HttpClient, private authService:AuthenticationService) { }

  fetchContent(id:string)
  {
    console.log(`baseUrl1:${this.baseUrl}`); 
    const url = `${this.baseUrl}api/v1/Blogs?blogId=${id}`; 
    return this.authService.getAuth().pipe(
      exhaustMap((data) => {
                
        const header = new HttpHeaders().set(
          "Authorization",
          `Bearer ${data.token}`
         
          
        );
        return this.httpClient
          .get<ContentModel>(url, { headers: header })
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }
}
