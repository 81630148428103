<!-- Categories Widget -->
<div class="card my-4">
  <h5 class="card-header">Categories</h5>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-12" *ngFor="let category of categories">

        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{category.name}}

              </mat-panel-title>
              <mat-panel-description>

              </mat-panel-description>
            </mat-expansion-panel-header>
            <ul class="list-unstyled mb-0" *ngFor="let blogInfo of category.blogInfos">
              <li>
                <a appHoverColor [routerLink]="['/blog',blogInfo.blogId]">{{blogInfo.blogTitle}}</a>
              </li>
              <br>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>

      </div>

    </div>
  </div>
</div>