import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor() { }

  isCollapse:boolean = true; 
  ngOnInit() {
  }

  toggleCollapsed(){
    console.log(this.isCollapse); 
this.isCollapse = !this.isCollapse; 
  }

}
