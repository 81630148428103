import { Injectable } from '@angular/core';
import { AppConfig } from './app-config.model';
import {HttpClient} from '@angular/common/http'
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  static setting: AppConfig

  constructor(private http:HttpClient) { }

  load()
  {
    const jsonFile = `assets/config/config.${environment.name}.json`; 
    console.log(jsonFile); 
    return new Promise<void>((resolve, reject)=> {
      this.http.get(jsonFile).toPromise().then(response => {
        AppConfigService.setting = <AppConfig>response; 
        resolve(); 
      }).catch(response =>{
        reject(`could nod load file '${jsonFile}:${JSON.stringify(response)}`); 
      } )
    })
  }
}
